// import external dependencies
import 'jquery';

// Import everything from autoload
import "./autoload/_bootstrap"
import "slick-carousel/slick/slick.min"
import "izimodal/js/iziModal.min"
import "inputmask/dist/jquery.inputmask.min"
import "swiper/swiper-bundle.min"
import "select2"
import "imask/dist/imask.min"


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    // Home page
    home,
    // About Us page, note the change from about-us to aboutUs.
    aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
