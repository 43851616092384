/* eslint-disable */
import generateMarkers from "../classes/generateMarkers";
import Swiper from 'swiper/bundle';

export default {
    init() {
        this.burgerMenu();
        this.dropdownMenu();
        this.securityPlus();
        this.comparisons();
        this.exampleSlider();
        this.switchBlock();
        this.carSlider();
        this.programsSlider();
        this.select();
        this.map();
        this.activeServices();
        this.smoothScroll();
        this.homeSlider();
        this.faq();
        this.workSlider();
        this.serviceSlider();
        this.sliderDirection();
        this.reviewSlider();
        this.footerMenu();
        this.servicesList();
        this.siteMap();
        this.tabs();
    },
    tabs() {
        let self = this;
        $("ul.tabs__caption").on("click", "li:not(.active)", function () {
            $(this)
                .addClass("active")
                .siblings()
                .removeClass("active")
                .closest("div.tabs")
                .find("div.tabs__content")
                .removeClass("active")
                .eq($(this).index())
                .addClass("active");
            let $exampleSlider = $('.tabs__content.active .tabs__content-slider .slider');

            if (!$exampleSlider.hasClass("slick-initialized")) {
                self.exampleSlider();
            } else {
                $exampleSlider.slick('unslick');
                self.exampleSlider();
            }
        });
    },
    siteMap() {
        $('.wsp-pages-title').click(function () {
            $(this).parent().find('.wsp-pages-list').slideToggle();
            $(this).parent().find('.wsp-pages-list').toggleClass('active');
        })
        $('.wsp-servicess-title').click(function () {
            $(this).parent().find('.wsp-servicess-list').slideToggle();
            $(this).parent().find('.wsp-servicess-list').toggleClass('active');
        })
        $('.wsp-works-title').click(function () {
            $(this).parent().find('.wsp-works-list').slideToggle();
            $(this).parent().find('.wsp-works-list').toggleClass('active');
        })
    },
    footerMenu() {
        if ($('.footer__services ul li').length > 6) {
            const footerBtn = $('.footer__services .more');
            footerBtn.css('display', 'inline-block');
            footerBtn.click(function () {
                $('.footer__services ul').toggleClass('open');
            });
        }
    },
    servicesList() {
        if ($('.services__items .servicesItem').length > 6) {
            $('.services__btn').show();
            $('.services__btn span').click(function () {
                $('.services__items').toggleClass('open');
                $('.services__btn').hide();
            });
        } else {
            $('.services__btn').hide();
        }
    },
    reviewSlider() {
        let galleryThumb = new Swiper('.review__slider', {
            mode: 'horizontal',
            slidesPerView: 1.1,
            spaceBetween: 16,
            centeredSlides: false,
            loop: false,
            navigation: {
                nextEl: ".review__arrow .next-btn",
                prevEl: ".review__arrow .prev-btn"
            },
            scrollbar: {
                el: '.workReview .swiper-scrollbar',
                hide: false
            },
            watchSlidesProgress: true,
            breakpoints: {
                1024: {
                    slidesPerView: 3.2,
                },
                769: {
                    slidesPerView: 2.2,
                    spaceBetween: 32,
                },
                576: {
                    slidesPerView: 1.6,
                },
                400: {
                    slidesPerView: 1.1,
                }
            }
        })
    },
    partnersSlider() {
        let galleryThumb = new Swiper('.partners__slider', {
            mode: 'horizontal',
            slidesPerView: 1.1,
            spaceBetween: 16,
            centeredSlides: false,
            loop: false,
            navigation: {
                nextEl: ".partners__arrow .next-btn",
                prevEl: ".partners__arrow .prev-btn"
            },
            scrollbar: {
                el: '.workReview .swiper-scrollbar',
                hide: false
            },
            watchSlidesProgress: true,
            breakpoints: {
                1024: {
                    slidesPerView: 3.2,
                },
                769: {
                    slidesPerView: 2.2,
                    spaceBetween: 32,
                },
                576: {
                    slidesPerView: 1.6,
                },
                400: {
                    slidesPerView: 1.1,
                }
            }
        })
    },
    workSlider() {
        let galleryThumb = new Swiper('.ourWork__slider', {
            mode: 'horizontal',
            slidesPerView: 1.2,
            navigation: {
                nextEl: ".ourWork__arrow .next-btn",
                prevEl: ".ourWork__arrow .prev-btn"
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false
            },
            watchSlidesProgress: true,
            breakpoints: {
                769: {
                    slidesPerView: 3,
                },
                576: {
                    slidesPerView: 2.6,
                },
                400: {
                    slidesPerView: 1.2,
                }
            }
        })
    },
    serviceSlider() {
        let galleryThumbs = new Swiper('.workContent__slider', {
            mode: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 32,
            centeredSlides: false,
            loop: true,
            navigation: {
                nextEl: ".swiper-navigation .next",
                prevEl: ".swiper-navigation .prev"
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: false
            },

            scrollbarHide: false,
            autoplay: {
                delay: 3000,
            },
            speed: 1000,
            breakpoints: {
                1024: {
                    slidesPerView: 3.2,
                },
                576: {
                    slidesPerView: 1.6,
                },
                400: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                }
            }
        });
    },
    sliderDirection() {
        $(document).ready(function () {
            const padding = $('.footer .container').offset().left;
            $('.workContent__slider .swiper-wrapper').css('padding-left', padding + 10);
            $('.review__slider').css('margin-left', padding + 10);
        })
    },
    faq() {
        $('.faq__list .item').each(function () {
            $(this).find('.accordion').click(function () {
                $(this).parent().find('.panel').slideToggle();
                $(this).parent().parent().toggleClass('active');
            })
        });
    },
    homeSlider() {
        $('.topBannerHome__wrap').slick({
            slidesToShow: 1,
            appendArrows: $('.topBannerHome-arrow'),
            prevArrow: $('.topBannerHome-arrow .prev'),
            nextArrow: $('.topBannerHome-arrow .next'),
            dots: true,
            appendDots: $('.topBannerHome__nav .dots'),
            infinite: true,
            autoplay: true,
            autoplaySpeed: 1500,
        });
    },

    smoothScroll() {
        $(document).on('click', '.not-child a[href^="#"]', function (e) {
            e.preventDefault();
            let $hash = $(this).attr('href');
            let $id = $($hash);

            if ($id.length === 0) {
                return;
            }

            let pos = $id.offset().top - 120;

            $('body, html').animate({scrollTop: pos});
        });
    },

    map() {
        let contacts = window.site_options.coordinate;
        let data = {
            icon: contacts.icon,
            lat: contacts.lat,
            lng: contacts.lng,
            address: contacts.address,
            title: contacts.title,
            zoom: parseInt(contacts.zoom),
        };

        let map = document.querySelector('#map');
        let map2 = document.querySelector('#map2');

        if (map) {
            setTimeout(() => {
                new generateMarkers(data, 'map', [contacts.c_lat, contacts.c_lng], data.zoom);
            }, 1000);
        }

        if (map2) {
            setTimeout(() => {
                new generateMarkers(data, 'map2', [contacts.c_lat, contacts.c_lng], data.zoom);
            }, 1000);
        }

    },

    burgerMenu() {
        $(document).on('click', '.burger, .overlay', function () {
            let $banner = $('.banner');
            $('.burger').toggleClass('clicked');
            $('.overlay').toggleClass('show');
            $('nav').toggleClass('show');
            $('body').toggleClass('overflow');
            $('.header__logo').toggleClass('active');
            $('.header').toggleClass('open');

            if ($('div.burger').hasClass('clicked')) {
                setTimeout(function () {
                    $('.headerLogo__logo svg path').css('fill', 'white');
                    $banner.css('position', 'fixed');
                }, 400);
            } else {
                $banner.css('position', 'relative');
                $('.headerLogo__logo svg path').css('fill', '#9086BB')
            }
        });

        $(document).on('click', '.burger.clicked', function () {
            $('.show__wrap').removeClass('active');
        });

        let menu = $('ul.main .parent.has-child');

        menu.each((index, item) => {
            $(item).attr('data-index', index);
            $(item).click(function () {
                const index = $(this).data().index;
                const menu = $('.burgerMenu ul.main_menu .parent.has-child');
                const menuWrap = $('.show__wrap');
                menu.removeClass('active');
                menuWrap.removeClass('active');
                menu.filter((ind) => ind === index).addClass('active');
                menuWrap.addClass('active');
            });
        });
    },

    dropdownMenu() {
        let menu = $('.header__wrap .items .main_menu li.has-child');

        menu.each(function () {
            $(this).mouseover(function () {
                menu.removeClass('active')
                $(this).addClass('active');
            });
        })

        $('.header__wrap .items .child-menu.dropdown').mouseout(function () {
            $(this).parent().removeClass('active');
        });
    },

    securityPlus() {
        $(document).mouseup(function (e) {
            let div = $(".security__items-item .text.active");

            if (!div.is(e.target)
                && div.has(e.target).length === 0) {
                div.removeClass('active');
                div.parent().find('.figure img.plus.active').removeClass('active');
            }
        });

        $(document).on('click', '.security__items-item .figure img.plus', function () {
            $(this).toggleClass('active');
            $(this).parent().parent().find('.text').toggleClass('active');
        });
    },

    comparisons() {
        jQuery(document).ready(function ($) {
            let dragging = false,
                scrolling = false,
                resizing = false;

            let imageComparisonContainers = $('.cd-image-container');

            checkPosition(imageComparisonContainers);
            $(window).on('scroll', function () {
                if (!scrolling) {
                    scrolling = true;
                    (!window.requestAnimationFrame)
                        ? setTimeout(function () {
                            checkPosition(imageComparisonContainers);
                        }, 100)
                        : requestAnimationFrame(function () {
                            checkPosition(imageComparisonContainers);
                        });
                }
            });

            imageComparisonContainers.each(function () {
                let actual = $(this);
                drags(actual.find('.cd-handle'), actual.find('.cd-resize-img'), actual, actual.find('.cd-image-label[data-type="original"]'), actual.find('.cd-image-label[data-type="modified"]'));
            });

            $(window).on('load', function () {

                if (!resizing) {
                    resizing = true;
                }
            });

            function checkPosition(container) {
                container.each(function () {
                    let actualContainer = $(this);
                    actualContainer.addClass('is-visible');

                });

                scrolling = false;
            }

            function drags(dragElement, resizeElement, container) {
                dragElement.on("mousedown touchstart vmousedown", function (e) {
                    dragElement.addClass('draggable');
                    resizeElement.addClass('resizable');

                    let dragWidth = dragElement.outerWidth(),
                        xPosition = dragElement.offset().left + dragWidth - e.pageX,
                        containerOffset = container.offset().left,
                        containerWidth = container.outerWidth(),
                        minLeft = containerOffset + 10,
                        maxLeft = containerOffset + containerWidth - dragWidth - 10;

                    dragElement.parents().on("mousemove touchmove vmousemove", function (e) {
                        if (!dragging) {
                            dragging = true;
                            (!window.requestAnimationFrame)
                                ? setTimeout(function () {
                                    animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement);
                                }, 100)
                                : requestAnimationFrame(function () {
                                    animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement);
                                });
                        }
                    }).on("mouseup touchend vmouseup", function () {
                        dragElement.removeClass('draggable');
                        resizeElement.removeClass('resizable');
                    });
                    e.preventDefault();
                }).on("mouseup touchend vmouseup", function () {
                    dragElement.removeClass('draggable');
                    resizeElement.removeClass('resizable');
                });
            }

            function animateDraggedHandle(e, xPosition, dragWidth, minLeft, maxLeft, containerOffset, containerWidth, resizeElement) {
                var leftValue = e.pageX + xPosition - dragWidth;
                if (leftValue < minLeft) {
                    leftValue = minLeft;
                } else if (leftValue > maxLeft) {
                    leftValue = maxLeft;
                }

                let widthValue = (leftValue + dragWidth / 2 - containerOffset) * 100 / containerWidth + '%';

                $('.draggable').css('left', widthValue).on("mouseup touchend vmouseup", function () {
                    $(this).removeClass('draggable');
                    resizeElement.removeClass('resizable');
                });

                $('.resizable').css('width', widthValue);

                dragging = false;
            }
        });
    },

    exampleSlider() {
        let $exampleSlider = $('.tabs__content.active .tabs__content-slider .slider');

        let slidesCount = $exampleSlider.length;
        $('.amount .count').html(slidesCount);

        if ($exampleSlider.length) {
            $exampleSlider.slick({
                slidesToShow: 1,
                arrows: true,
                dots: false,
                infinite: false,
                prevArrow: $('.prevR'),
                nextArrow: $('.nextR'),
                responsive: [
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 376,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ]
            });

            $exampleSlider.on('afterChange', function (event, slick, currentSlide, nextSlide) {
                let current = $('.slick-slider').find('.slick-current').attr('data-slick-index');
                $('.amount .current').html(Number(current) + 1);
            });
        }
    },

    switchBlock() {
        let $exampleButton = $('.example__button');
        $exampleButton.find('.button').on("click", function () {
            $(".example__button .button.active").removeClass('active');
            $(this).addClass("active");
        });

        $exampleButton.find('.button').click(function () {
            $exampleButton.find('.button').removeClass('active');
            $(this).addClass('active');
            $('.block').removeClass('block-active');
            $($(this).attr('data-class')).addClass('block-active');
        })

    },

    carSlider() {
        let $airbagItems = $('.airbag__items');
        let $range = $('.range');

        $airbagItems.on('init reInit', function (event, slick) {
            let amount = slick.slideCount;
            $range.attr('max', amount);
        });
        $airbagItems.on('afterChange', function (e, slick, currentSlide) {
            $range.val(currentSlide + 1);
        });

        $range.on('input change', function () {
            $airbagItems.slick('slickGoTo', this.value - 1);
        });
        if ($(window).width() < 770) {
            $airbagItems.slick({
                slidesToShow: 2,
                arrows: false,
                dots: false,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 376,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ]
            });
        }
    },

    programsSlider() {
        let $programsItems = $('.programs__items');
        let $rangeP = $('.rangeP');

        $programsItems.on('init reInit', function (event, slick) {
            let amount = slick.slideCount;
            $rangeP.attr('max', amount);
        });
        $programsItems.on('afterChange', function (e, slick, currentSlide) {
            $rangeP.val(currentSlide + 1);
        });

        $rangeP.on('input change', function () {
            $programsItems.slick('slickGoTo', this.value - 1);
        });
        if ($(window).width() < 770) {
            $programsItems.slick({
                slidesToShow: 2,
                arrows: false,
                dots: false,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                    {
                        breakpoint: 376,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ]
            });
        }
    },

    select() {
        $(document).ready(function () {
            $('.newSelect').each(function () {
                $(this).select2({
                    minimumResultsForSearch: -1,
                });
            });
        });
    },

    /* global wpcf7 */
    fixModalJs(modal) {
        setTimeout(() => {
            if (modal.length > 0) {
                wpcf7.init(modal[0]);
            }
        }, 0);
    },

    activeServices() {
        $('figure.cd-image-container').each(function () {
            if ($(this).children().length <= 1) {
                $(this).toggleClass('active')
            }
        })
    },

    phoneValid(phone) {
        let defaultLength = 19;
        let isValidPhone = phone.val().length === defaultLength;

        if (!isValidPhone) {
            phone.css('border-color', 'red')
        } else {
            phone.css('border-color', '#aaa')
        }

        return isValidPhone;
    },

    /* JavaScript to be fired on all pages, after page specific JS is fired */
    finalize() {
    }
}
