/* global DG */
export default class generateMarkers {
    constructor(data, mapSelector, center = [50.4548466, 30.5614487], zoom = 11) {
        this.loadScript();

        this.markers = [];
        this.data = data;
        this.zoom = zoom;
        this.center = center;
        this.mapSelector = mapSelector;

        this.imageObj = new Image();
        this.imageObj.src = data.icon;

        setTimeout(() => {
            this.initMap();
        }, 1000);

    }

    loadScript() {
        const script = document.createElement('script');
        script.src = `//maps.api.2gis.ru/2.0/loader.js?pkg=full`;
        script.async = true;

        document.body.appendChild(script);
    }

    initMap() {
        window.DG.then(() => {
            this.map = DG.map(this.mapSelector, {
                center: this.center,
                zoom: this.zoom,
                touchZoom: true
            });
            this.setMarker(this.data, this.imageObj);
        });
    }

    setMarker(markerData, logo) {
        this.markerIcon = window.DG.icon({
            iconUrl: logo.src,
            iconSize: [logo.width, logo.height],
            iconAnchor: [logo.width / 2, logo.height]
        });
        let content = this.creatPopup(markerData);
        this.markers = window.DG.marker([markerData.lat, markerData.lng], {
            label: markerData.address, icon: this.markerIcon
        }).addTo(this.map).bindPopup(content);
    }

    creatPopup(info) {
        // let popup = document.createElement('div')
        let popup = '<div>';

        if ( info.title ) {
            popup += `<strong>${info.title}</strong>`;
        }

        if ( info.address ) {
            popup += `<div>${info.address}</div>`;
        }

        popup += '</div>';

        return popup;
    }
}
